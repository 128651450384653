
.input-container {
    opacity: 1;
    -webkit-animation: fadeIn 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) 0.175s 1 normal forwards;
    animation: fadeIn 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) 0.175s 1 normal forwards;
    background-color: white;
}


.input-container {
    position: relative;
    height: 2.9em;
    border: 0 solid #ECECEC;
    border-bottom-width: 2px;
}


.input-container > input {
    display: block;
    position: absolute;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    border: 0;
    top: 1.2em;
    background: none;
    z-index: 1;
    padding: 1px;
    font-size: 1em;
    letter-spacing: 0.012em;
}

.input-container > label {
    display: block;
    position: absolute;
    /*margin-top: 2px;*/
    padding: 1px;
    /*letter-spacing: 0.012em;*/
    color: #ACACAC;
    font-size: 1em;
    -webkit-animation-name: labelIn;
    animation-name: labelIn;
    -webkit-animation-duration: 0.35s;
    animation-duration: 0.35s;
    -webkit-animation-direction: reverse;
    animation-direction: reverse;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
    animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
}

.input-container > input:focus + label, .input-container input:valid + label {
    -webkit-animation-name: labelOut;
    animation-name: labelOut;
    -webkit-animation-duration: 0.35s;
    animation-duration: 0.35s;
    -webkit-animation-direction: normal;
    animation-direction: normal;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
    animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
}


.input-container > *:focus {
    outline: 0;
}

@keyframes labelIn {
    0% {
        left: 1px;
        opacity: 1;
        top: 1.2em;
        font-size: 1em;
        font-weight: 300;
    }
    50% {
        font-size: 1em;
        left: 1em;
        opacity: 0;
        top: 1.2em;
        font-weight: 300;
    }
    50.01% {
        font-size: 0.65em;
        left: 1em;
        opacity: 0;
        top: 0.2em;
        color: #0075d5;
        font-weight: 400;
    }
    100% {
        font-size: 0.65em;
        opacity: 1;
        left: 1px;
        top: 0.2em;
        color: #0075d5;
        font-weight: 400;
    }
}

@keyframes labelOut {
    0% {
        left: 1px;
        opacity: 1;
        top: 1.2em;
        font-size: 1em;
        font-weight: 300;
    }
    50% {
        font-size: 1em;
        left: 1em;
        opacity: 0;
        top: 1.2em;
        font-weight: 300;
    }
    50.01% {
        font-size: 0.65em;
        left: 1em;
        opacity: 0;
        top: 0.2em;
        color: #0075d5;
        font-weight: 400;
    }
    100% {
        font-size: 0.65em;
        opacity: 1;
        left: 1px;
        top: 0.2em;
        color: #0075d5;
        font-weight: 400;
    }
}

/*@-webkit-keyframes fadeIn {*/
/*    from {*/
/*        opacity: 0;*/
/*    }*/

/*    to {*/
/*        opacity: 1;*/
/*    }*/
/*}*/

/*@keyframes fadeIn {*/
/*    from {*/
/*        opacity: 0;*/
/*    }*/

/*    to {*/
/*        opacity: 1;*/
/*    }*/
/*}*/



